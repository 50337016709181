// @ts-nocheck
import { makeStyles } from "@material-ui/core/styles";

const dialogTypes = {
  error: {
    confirmColor: "white",
    confirmBackground: "#FF1744",
    confirmHoverBackground: "rgb(240, 25, 73)",
  },
  success: {
    confirmColor: "black",
    confirmBackground: "#00d659",
    confirmHoverBackground: "#00a143",
  },
};

export const useStyles = makeStyles(() => ({
  dialogPaper: {
    width: (props) => props.width || undefined,
    minWidth: (props) => props.minWidth || "40%",
    minHeight: (props) => props.minHeight || "280px",
  },
  closeButton: {
    position: "absolute",
    right: "30px",
    padding: "10px",
    color: "black",
  },
  dialogTitle: {
    fontWeight: 500,
    fontSize: 28,
    lineHeight: "32px",
    padding: "40px 40px 24px 40px",
    /* identical to box height, or 114% */

    display: "flex",
    alignItems: "center",
    letterSpacing: "-0.02em",

    /* Blue Gray / 10 */

    color: "#3c3e49",
  },
  messageContainer: {
    padding: "0 40px 60px 40px",
  },
  message: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    color: "#3C3E49",
    whiteSpace: "pre-wrap",
  },
  actionsRoot: {
    padding: "0",
    borderTop: "1px solid #e8e8e8",
  },
  cancelButton: {
    cursor: "pointer",
    textTransform: "none",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    textDecorationLine: "underline",
    color: "#3c3e49",
  },
  confirmButton: {
    cursor: "pointer",
    textTransform: "none",

    /* Red / A4 */
    background: (props) =>
      props.confirmBackground ||
      dialogTypes[props.dialogType || "success"]?.confirmBackground,
    borderRadius: "3px",
    /* Auto layout */

    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: " 20px 48px",
    color: (props) =>
      props.confirmColor ||
      dialogTypes[props.dialogType || "success"].confirmColor,
    "&:hover": {
      background: (props) =>
        props.confirmHoverBackground ||
        dialogTypes[props.dialogType || "success"].confirmHoverBackground,
    },
  },
}));
