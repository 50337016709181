import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import {
  BlockComponent,
  generateRequestMessage,
} from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  showHeaderBar: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  token: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  loginUserDetails?: any;
  forgotPasswordEmail: any;
  onboardingResponse: any;
  loading: boolean;
  showOtp: boolean;
  showOtpError: boolean;
  showPassword: boolean;
  showConfirmPassword: boolean;
  otpExpireDate?: Date;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  passwordConfirmationFormRef: React.RefObject<unknown>;
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  labelTitle: string = "";
  forgotPasswordApiCallId: string = "";
  resetPasswordApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.passwordConfirmationFormRef = React.createRef();
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      token: "",
      forgotPasswordEmail: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      onboardingResponse: {},
      loading: false,
      showOtp: false,
      showOtpError: false,
      showPassword: false,
      showConfirmPassword: false,
    };
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  togglePasswordVisibility = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  toggleConfirmPasswordVisibility = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };

  resendOtp = () => {
    this.handleForgotPassword({
      email: this.state.forgotPasswordEmail,
    });
  };

  clearOtpError = () => {
    this.setState({ showOtpError: false });
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  // Customizable Area End

  handleForgotPassword = (data: any) => {
    const requestMessage = generateRequestMessage(
      data.otp
        ? configJSON.forgotApiWithOtpEndPoint
        : configJSON.forgotAPiEndPoint,
      configJSON.loginAPiMethod
    );

    this.addBodyToMessage(requestMessage, data);
    this.forgotPasswordApiCallId = requestMessage.messageId;
    this.send(requestMessage);
    this.setState({ loading: true });
  };

  forgotPassword = async (values: any) => {
    let _data: any = {
      email: values.email,
    };
    if (this.state.showOtp && !values.otp) {
      return this.setState({ showOtpError: true });
    } else {
      this.setState({ showOtpError: false });
      _data.otp = values.otp;
    }

    this.setState({
      forgotPasswordEmail: _data.email,
    });
    return this.handleForgotPassword(_data);
  };

  resetPassword = (values: any) => {
    const _data = { ...values };
    delete _data.email;
    delete _data.otp;

    this.setState({
      loading: true,
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resetPasswordApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPasswordApiEndPoint
    );
    const headers = {
      "Content-Type": "application/json",
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ ..._data, token: this.state.token })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    this.setState({ loading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.forgotPasswordApiCallId) {
        if (this.checkResponseError(responseJson)) {
          this.showError(configJSON.someThingWentWrong, responseJson);
          this.setState({ loading: false });
        } else if (responseJson.token) {
          // validated otp
          this.setState({
            token: responseJson.token,
            loading: false,
          });
        } else {
          this.props.showHeaderBar({
            message: responseJson?.messages?.[0],
            type: "success",
          });

          const otpExpireDate = new Date(Date.now() + 5 * 60 * 1000);
          this.setState({ loading: false, showOtp: true, otpExpireDate });
        }
      } else if (apiRequestCallId === this.resetPasswordApiCallId) {
        if (responseJson.errors || responseJson.error) {
          this.props.showHeaderBar({
            message: responseJson.error || responseJson.errors[0],
            type: "error",
          });
        } else {
          this.props.showHeaderBar({
            type: "success",
            message:
              "password successfully changed. You will be redirected to login page",
          });
          setTimeout(() => {
            // @ts-ignore
            this.props.history.push("/login");
          }, 4000);
        }
        this.setState({ loading: false });
      }
    }
    // Customizable Area End
  }
}
