// @ts-nocheck
import React from 'react'
import ReactDOM from 'react-dom'
import './userConfirmation.css'

const UserConfirmation = (message: any, callback: any) => {
  if (document.getElementById('user-confirmation-dialog')) {
    return true;
  }
  const container = document.createElement('div')
  container.setAttribute('custom-confirmation-navigation', '')
  document.body.appendChild(container)

  const closeModal = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container)
    document.body.removeChild(container)
    localStorage.setItem('routeChanged', callbackState)
    callback(callbackState)
  }

  ReactDOM.render(
      <div
          id='user-confirmation-dialog'
          role='presentation'
          className='MuiDialog-root notification-create-dialog'
          style={{ position: 'fixed', zIndex: 1300, inset: 0 }}>
        <div
            className='MuiBackdrop-root'
            aria-hidden='true'
            style={{ opacity: 1, transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' }}
        />
        <div tabIndex={0} data-test='sentinelStart' />
        <div
            className='MuiDialog-container MuiDialog-scrollPaper'
            role='none presentation'
            tabIndex={-1}
            style={{ opacity: 1, transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' }}>
          <div
              className='MuiPaper-root MuiDialog-paper Component-dialogPaper-user-confirmation  MuiDialog-paperScrollPaper MuiDialog-paperWidthSm MuiPaper-elevation24 MuiPaper-rounded'
              role='dialog'
              aria-describedby='alert-dialog-description'
              aria-labelledby='alert-dialog-title'>
            <div className='MuiDialogTitle-root' id='alert-dialog-title'>
              <h2 className='MuiTypography-root MuiTypography-h6 h6-user-confirmation'>
                Are you sure?
                <button
                    className='MuiButtonBase-root MuiIconButton-root Component-closeButton-user-confirmation'
                    tabIndex={0}
                    type='button'
                    onClick={() => closeModal(false)}
                    aria-label='close'>
                <span className='MuiIconButton-label'>
                  <svg
                      className='MuiSvgIcon-root'
                      focusable='false'
                      viewBox='0 0 24 24'
                      aria-hidden='true'>
                    <path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z' />
                  </svg>
                </span>
                  <span className='MuiTouchRipple-root' />
                </button>
              </h2>
            </div>
            <form action='#' autoComplete='off' noValidate>
              <div className='content'>
                <span className='confirm-message'>If you leave this page, any unsaved changes will be lost.</span>
              </div>
            </form>
            <div className='MuiDialogActions-root Component-actionsRoot-user-confirmation MuiDialogActions-spacing'>
              <button
                  className='MuiButtonBase-root MuiButton-root Component-cancelButton-user-confirmation  MuiButton-text'
                  tabIndex={0}
                  onClick={() => closeModal(false)}
                  type='button'>
                <span className='MuiButton-label'>Cancel</span>
                <span className='MuiTouchRipple-root' />
              </button>
              <button
                  className='MuiButtonBase-root MuiButton-root Component-confirmButton-user-confirmation  MuiButton-text'
                  tabIndex={0}
                  onClick={() => closeModal(true)}
                  type='button'>
                <span className='MuiButton-label'>Yes, leave page</span>
                <span className='MuiTouchRipple-root' />
              </button>
            </div>
          </div>
        </div>
        <div tabIndex={0} data-test='sentinelEnd' />
      </div>,
      container
  )
}

export default UserConfirmation
