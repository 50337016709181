//@ts-nocheck
import React, { useEffect, useState } from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  withStyles,
  createStyles,
  Grid,
  FormControlLabel,
  withTheme,
} from "@material-ui/core";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";
import TextField from "./InputFieldWithFormik.web";
import { withRouter } from "react-router-dom";
import withHeaderActionBar from "../../studio-store-ecommerce-components/src/HOC/withHeadeActionBar.Web";
import CircularProgress from "@material-ui/core/CircularProgress";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { withDialog } from "../../studio-store-ecommerce-components/src/Dialog/withDialog";
// Customizable Area End

import ForgotPasswordController from "./ForgotPasswordController.web";

export class ForgotPassword extends ForgotPasswordController {
  render() {
    const { classes, theme } = this.props;

    return this.state.token ? (
      <Formik
        innerRef={this.passwordConfirmationFormRef}
        initialValues={{
          password: "",
          password_confirmation: "",
        }}
        validationSchema={Yup.object({
          password: Yup.string()
            .required("Please enter a password")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>'\^\*])(?=.{8,})/,
              "Must contain 8 characters including 1 uppercase letter, 1 digit and 1 symbol"
            ),
          password_confirmation: Yup.string()
            .required("This field is required")
            .oneOf([Yup.ref("password")], "Passwords must match")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>'\^\*])(?=.{8,})/,
              "Must contain 8 characters including 1 uppercase letter, 1 digit and 1 symbol"
            ),
        })}
        onSubmit={this.resetPassword}
        enableReinitialize
      >
        {(formikProps) => {
          const {
            values,
            handleBlur,
            handleChange,
            setFieldValue,
            errors,
            touched,
          } = formikProps;
          return (
            <Form autoComplete="off" noValidate>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  height: theme.breakpoints.down("md") ? "100%" : "100vh",
                  background: "#f9f8f5",
                }}
              >
                <Grid item md={3} sm={6} xs={8}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography
                        className={classes.backtosignin}
                        onClick={() => {
                          this.props.history.push("/login");
                        }}
                        align="start"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          width="14"
                          height="12"
                          viewBox="0 0 14 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginRight: 4 }}
                        >
                          <path
                            d="M12.625 6H1.375"
                            stroke="#676B7E"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M6.625 0.75L1.375 6L6.625 11.25"
                            stroke="#676B7E"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Back to sign in
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        py={3}
                        style={{
                          background: "#fff",
                          border: "2px solid #ECEDF0",
                          borderRadius: "3px",
                        }}
                      >
                        <Grid container justifyContent="center" spacing={2}>
                          <Grid item xs={10}>
                            <Typography
                              variant="h6"
                              align="left"
                              className={classes.signinHeading}
                            >
                              Change password
                            </Typography>
                          </Grid>

                          <Grid item xs={10}>
                            <Field
                              autoComplete="off"
                              type={
                                this.state.showPassword ? "text" : "password"
                              }
                              component={TextField}
                              onChange={handleChange}
                              name="password"
                              value={values.password || ""}
                              fullWidth
                              label="Password"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      style={{ fontSize: "1.2rem" }}
                                      onClick={this.togglePasswordVisibility}
                                    >
                                      {this.state.showPassword ? (
                                        <Visibility
                                          style={{ fontSize: "1.2rem" }}
                                        />
                                      ) : (
                                        <VisibilityOff
                                          style={{ fontSize: "1.2rem" }}
                                        />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              error={!!(errors?.password && touched?.password)}
                              helperText={
                                errors?.password && touched?.password
                                  ? errors?.password
                                  : ""
                              }
                            />
                          </Grid>
                          {this.state.showOtp && (
                            <Grid item xs={10}>
                              <Field
                                autoComplete="off"
                                type={
                                  this.state.showConfirmPassword
                                    ? "text"
                                    : "password"
                                }
                                component={TextField}
                                onChange={handleChange}
                                name="password_confirmation"
                                value={values.password_confirmation || ""}
                                fullWidth
                                label="Confirm password"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle confirm password visibility"
                                        style={{ fontSize: "1.2rem" }}
                                        onClick={
                                          this.toggleConfirmPasswordVisibility
                                        }
                                      >
                                        {this.state.showConfirmPassword ? (
                                          <Visibility
                                            style={{ fontSize: "1.2rem" }}
                                          />
                                        ) : (
                                          <VisibilityOff
                                            style={{ fontSize: "1.2rem" }}
                                          />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                error={
                                  errors?.password_confirmation &&
                                  touched?.password_confirmation
                                    ? true
                                    : false
                                }
                                helperText={
                                  errors?.password_confirmation &&
                                  touched?.password_confirmation
                                    ? errors?.password_confirmation
                                    : ""
                                }
                              />
                            </Grid>
                          )}

                          <Grid item xs={10}>
                            <Button
                              data-testid="change-password-button"
                              color="primary"
                              type={this.state.loading ? "button" : "submit"}
                              variant="contained"
                              style={{
                                background: "#00D659",
                                borderRadius: "3px",
                              }}
                              classes={{
                                label: classes.buttonText,
                              }}
                              fullWidth
                            >
                              {this.state.loading ? (
                                <CircularProgress size="16px" />
                              ) : (
                                "Update password"
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    ) : (
      <Formik
        initialValues={{
          email: "",
          otp: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email("Email must be in valid format.")
            .required("This field is required"),
        })}
        onSubmit={this.forgotPassword}
      >
        {(formikProps) => {
          const {
            values,
            handleBlur,
            handleChange,
            setFieldValue,
            errors,
            touched,
          } = formikProps;
          return (
            <Form autoComplete="off" noValidate>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  height: theme.breakpoints.down("md") ? "100%" : "100vh",
                  background: "#f9f8f5",
                }}
              >
                <Grid item md={3} sm={6} xs={8}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography
                        className={classes.backtosignin}
                        onClick={() => {
                          this.props.history.push("/login");
                        }}
                        align="start"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          width="14"
                          height="12"
                          viewBox="0 0 14 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginRight: 4 }}
                        >
                          <path
                            d="M12.625 6H1.375"
                            stroke="#676B7E"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M6.625 0.75L1.375 6L6.625 11.25"
                            stroke="#676B7E"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Back to sign in
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        py={3}
                        style={{
                          background: "#fff",
                          border: "2px solid #ECEDF0",
                          borderRadius: "3px",
                        }}
                      >
                        <Grid container justifyContent="center" spacing={2}>
                          <Grid item xs={10}>
                            <Typography
                              variant="h6"
                              align="left"
                              className={classes.signinHeading}
                            >
                              Forgotten password
                            </Typography>
                          </Grid>

                          <Grid item xs={10}>
                            <Field
                              disabled={
                                this.state.loading || this.state.showOtp
                              }
                              type="email"
                              component={TextField}
                              onChange={handleChange}
                              name="email"
                              value={values.email}
                              fullWidth
                              label="Email"
                            />
                          </Grid>
                          {this.state.showOtp && (
                            <Grid item xs={10}>
                              <Field
                                type="number"
                                component={TextField}
                                onChange={(event: any) => {
                                  handleChange(event);
                                  this.clearOtpError();
                                }}
                                name="otp"
                                value={values.otp}
                                fullWidth
                                label="Enter OTP"
                                placeholder="Enter OTP from email"
                                error={this.state.showOtpError || false}
                                helperText={
                                  this.state.showOtpError
                                    ? "This field is required"
                                    : " "
                                }
                              />
                            </Grid>
                          )}
                          {this.state.otpExpireDate && (
                            <Grid item xs={10}>
                              <CountdownTimer
                                expireDate={this.state.otpExpireDate}
                                resendOtp={this.resendOtp}
                              />
                            </Grid>
                          )}
                          <Grid item xs={10}>
                            <Button
                              data-testid="reset-password-button"
                              color="primary"
                              type={this.state.loading ? "button" : "submit"}
                              variant="contained"
                              style={{
                                background: "#00D659",
                                borderRadius: "3px",
                              }}
                              classes={{
                                label: classes.buttonText,
                              }}
                              fullWidth
                            >
                              {this.state.loading ? (
                                <CircularProgress size="16px" />
                              ) : (
                                "Reset password"
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
const ForgotPasswordWithRouter = withHeaderActionBar(
  withRouter(ForgotPassword)
);
export default withDialog(
  withStyles((theme) =>
    createStyles({
      signinHeading: {
        fontSize: "1rem",
        lineHeight: 1.33,
        fontWeight: 500,
        letterSpacing: "normal",
        color: "#3C3E49",
      },
      buttonText: {
        color: "#3C3E49",
        fontWeight: 500,
        fontSize: "12px",
        textTransform: "capitalize",
      },
      backtosignin: {
        color: "#676B7E",
        fontWeight: 400,
        fontSize: "0.75rem",
        cursor: "pointer",
      },
    })
  )(withTheme(ForgotPasswordWithRouter))
);

const timeToText = (remainingTime) => {
  if (remainingTime < 0) {
    return "Resend OTP";
  }
  const minutes = Math.floor(remainingTime / 60);
  const seconds = Math.floor(remainingTime - minutes * 60);
  return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
    2,
    "0"
  )}`;
};

const CountdownTimer = ({ expireDate, resendOtp }) => {
  const [remainingTime, setRemainingTime] = useState(300);
  let interval = null;

  useEffect(() => {
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(() => {
      setRemainingTime((expireDate - Date.now()) / 1000);
    }, 1000);
    return () => clearInterval(interval);
  }, [expireDate]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        color: "red",
        cursor: remainingTime < 0 ? "pointer" : undefined,
        textDecoration: remainingTime < 0 ? "underline" : undefined,
      }}
      onClick={remainingTime < 0 ? resendOtp : undefined}
    >
      {timeToText(remainingTime)}
    </div>
  );
};
