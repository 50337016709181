// @ts-nocheck
import * as React from "react";
import { Nav as NavContainer, NavItem, Button } from "reactstrap";
import RenderLink from "./RenderLink";
import "./assets/css/style.css";
import { date } from "yup";

interface NavProps {}
export class Nav extends React.Component {
  constructor(props: any) {
    super(props);
    // @ts-ignore
    this.state = {
      // @ts-ignore
      adminuser: null,
      loading: true,
      links: [
        {
          id: "home",
          label: "Home",
          iconName: "icon-union",
          link: "/",
        },
   
        {
          id: "course",
          label: "Courses",
          iconName: "icon-orders",
          link: "/courses",
        },

        {
          id: "student",
          label: "Students",
          iconName: "icon-customer",
          link: "/students",
        },
        {
          id: "brand",
          label: "Website & emails",
          iconName: "icon-website",
          subLinks: [
            {
              id: "branding",
              label: "Branding",
              link: "/website-and-emails/branding",
            },
            {
              id: "email",
              label: "Email Templates",
              link: "/website-and-emails/email-templates",
            },
            {
              id: "static",
              label: "Static Pages",
              link: "/website-and-emails/static-pages",
            },
          ],
        },
        {
          id: "business",
          label: "Business Settings",
          iconName: "icon-settings",
          subLinks: [
            {
              id: "store",
              label: "Store details",
              link: "/business-settings/store-details",
            },
            {
              id: "level",
              label: "Levels",
              link: "/business-settings/levels",
            },
            {
              id: 'instructor',
              label: 'Instructor',
              link: '/business-settings/instructors'
            },
            {
                id: 'subjects',
                label: 'Subjects',
                link: '/business-settings/subjects'
            },
            {
              id: "notifications",
              label: "Notifications",
              link: "/business-settings/notifications",
            },
          ],
        },
        {
          id: "account",
          label: "Account",
          iconName: "icon-user",
          subLinks: [
            {
              id: "accountoverview",
              label: "Overview",
              iconName: "icon-profile",
              link: "/account/overview",
            },
            {
              id: "profile",
              label: "Profile",
              iconName: "icon-profile",
              link: "/account/detail",
            },
          ],
        },
      ],
    };
  }

  componentDidMount() {
    if (localStorage.getItem("admintoken") && localStorage.getItem("adminuser")) {
      this.setState({
        adminuser: JSON.parse(localStorage.getItem("adminuser")) || {},
        loading: false,
      });
    }
  }
  render() {
    // @ts-ignore
    const adminuser = this.state.adminuser;
    const permissions = adminuser?.data?.attributes?.permissions;
    const permissionLinks = (data: any) => {
      if (data?.id && data?.subLinks) {
        const value1 = [];
        data?.subLinks?.map((values: any) =>
          permissions?.find((permission) => {
            if (permission === values?.id) {
              value1?.push(values);
              return values;
            }
          })
        );
        data.subLinks = value1;
        return data?.subLinks?.length ? data : undefined;
      }
      if (data) {
        const value2 = [];
        permissions?.find((permission) => {
          if (permission === data?.id) {
            value2?.push(data);
            return data;
          }
        });
        return value2[0];
      }
    };
    // @ts-ignore
    return (
      <NavContainer className="admin-nav" tabs vertical>
        {permissions &&
          this.state.links.map((data: any) =>
            permissions.includes("all") ? (
              <RenderLink data={data} />
            ) : permissionLinks(data) !== undefined ? (
              <RenderLink data={permissionLinks(data)} />
            ) : (
              ""
            )
          )}
      </NavContainer>
    );
  }
}
