import React from "react";
import EmailTemplatesController from "./EmailTemplatesController.web";
import Categories from "./Categories";
import { Scrollbars } from "react-custom-scrollbars";
import withSnackBar from "../../studio-store-ecommerce-components/src/HOC/withSnackBar.Web";
import { withDialog } from "../../studio-store-ecommerce-components/src/Dialog/withDialog";
import withLoader from "../../studio-store-elearning-components/src/withBrandingSpinnerController";
import withHeaderBar from "../../studio-store-ecommerce-components/src/HOC/withHeadeActionBar.Web";
import "./styles.css";
import { Tabs } from "./Utilities/Tabs";
import { Header } from "./Utilities/Header";

export class EmailTemplates extends EmailTemplatesController {
  render() {
    const { selectedTab, headerTabs, emailSettings } = this.state;
    return (
      <Scrollbars>
        <div className="email-templates">
          <Header onCreateClick={this.handleCreateClick}>
            <div className="tabs-wrapper">
              <Tabs
                headerTabs={headerTabs}
                onTabChange={this.handleTabChange}
                value={selectedTab}
              />
            </div>
          </Header>

          {headerTabs.length ? (
            <Categories
              categories={headerTabs[selectedTab].categories}
              emailSettings={emailSettings}
              onActiveChange={this.handleActiveChange}
              onEditClick={this.handleEditClick}
            />
          ) : null}
        </div>
      </Scrollbars>
    );
  }
}

export default withLoader(
  withSnackBar(withHeaderBar(withDialog(EmailTemplates)))
);
