import React from "react";
import MuiTabs from "@material-ui/core/Tabs";
import MuiTab from "@material-ui/core/Tab";
import { Tab } from "../Types";

interface TabProps {
  onTabChange?:
    | ((event: React.ChangeEvent<{}>, value: any) => void)
    | undefined;

  value: any;
  headerTabs: Tab[];
}

export const Tabs = ({ onTabChange, value, headerTabs }: TabProps) => {
  return (
    <MuiTabs
      classes={{
        root: "tabs-root",
        indicator: "tabs-indicator",
        flexContainer: "tabs-flex-container",
      }}
      value={value}
      onChange={onTabChange}
    >
      {headerTabs.map((headerTab) => (
        <MuiTab
          key={headerTab.tab_name}
          classes={{
            root: "tabs-root",
            labelIcon: "tab-label-icon",
            selected: "tab-label-selected",
          }}
          label={`${headerTab.tab_name} (${headerTab.count})`}
          data-testid={"email-tab-" + headerTab.tab_name}
        />
      ))}
    </MuiTabs>
  );
};
