// @ts-nocheck
import React from "react";
export type withToastProps = {
  showToast: (obj: any) => void;
};
function withToast(OriginalComponent) {
  return (props) => {
    const showToast = (options) => {
      // openToast({ ...options });
      window.alert(options.message);
    };
    return (
      <>
        <OriginalComponent {...props} showToast={showToast} />
      </>
    );
  };
}
export default withToast;
