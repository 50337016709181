import React from "react";

const configJSON = require("../config.js");

export const Header = ({
  children,
}: {
  onCreateClick: any;
  children: React.ReactNode;
}) => {
  return (
    <div className="header">
      <div className="header-title-wrapper">
        <span className="header-title">{configJSON.textEmailTemplates}</span>
      </div>
      {children}
    </div>
  );
};
