// @ts-nocheck
import React from "react";
import { DesktopToastContext } from "../Snackbar/HeaderToastContext";
import { headBarToastProops } from "../Snackbar/HeaderToastContext";
import { Prompt } from "react-router";

export type withHeadeActionBarProps = {
  showHeaderBar: (obj: headBarToastProops) => void;
  hideHeaderBar: () => void;
};

function withToast(OriginalComponent) {
  return (props) => {
    const {
      openToast,
      hideToast,
      toastOptions = {},
    } = React.useContext(DesktopToastContext);
    const [isBlocking, setIsBlocking] = React.useState(false);

    React.useEffect(() => {
      if (toastOptions?.type) {
        setIsBlocking(false);
      } else {
        setIsBlocking(toastOptions.status);
      }
    }, [toastOptions]);

    const showToast = (options) => {
      openToast?.({ ...options });
    };

    const closeToast = () => {
      hideToast?.();
    };

    return (
      <>
        <Prompt
          when={isBlocking}
          message="If you leave this page, any unsaved changes will be lost."
        />
        <OriginalComponent
          {...props}
          showHeaderBar={showToast}
          hideHeaderBar={closeToast}
        />
      </>
    );
  };
}
export default withToast;
