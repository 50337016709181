exports.routeEmailTemplates = "/website-and-emails/email-templates";
exports.routeCreate = "create";

exports.endpoint = "admin/v1/email_settings";
exports.endpointDefaultEmails = "admin/v1/default_email_settings";

exports.errorGetDetails = "Error on initialization";
exports.errorGetDefaultEmails = "Error on loading default email settings";
exports.errorSaveChanges = "Error on save changes";

exports.successSaveChanges = "Email template updated successfully";
exports.successStatusChange = "Email template status changed successfully";
exports.textActive = "Active";
exports.textInactive = "Inactive";
exports.textEmailTemplates = "Email Templates";
exports.textBack = "Back";
exports.textQuickEmailPreview = "Quick email preview";
exports.textCreateEmail = "Create Email";
exports.textEdit = "Edit";
exports.textTitle = "Title";
exports.textThisFieldIsRequired = "This field is required";
exports.textContent = "Content";
exports.textDesktop = "Desktop";
exports.textTablet = "Tablet";
exports.textMobile = "Mobile";
exports.textAddress =
  "Builder AI, 3rd Floor, 5 Merchant Square, Paddington, London, W2 6LG";
exports.textUnsubscribe = "Unsubscribe";
exports.textEmailPreferences = "Email preferences";
