import React from "react";
import EmailTemplatesCreateController from "./EmailTemplatesCreateController.web";
import { FormHelperText, TextField } from "@material-ui/core";
import Editor, {
  Preview,
} from "../../../studio-store-ecommerce-components/src/Editor-v4/Editor";
import MuiTabs from "@material-ui/core/Tabs";
import MuiTab from "@material-ui/core/Tab";
import withHeaderBar from "../../../studio-store-ecommerce-components/src/HOC/withHeadeActionBar.Web";
import withSnackBar from "../../../studio-store-ecommerce-components/src/HOC/withSnackBar.Web";
import { DesktopIcon, TabletIcon, MobileIcon } from "../assets";
import withLoader from "../../../studio-store-elearning-components/src/withBrandingSpinnerController";
import { Scrollbars } from "react-custom-scrollbars";
import { Prompt } from "react-router";
import { withDialog } from "../../../studio-store-ecommerce-components/src/Dialog/withDialog";
import { facebookIcon, linkedInIcon, twitterIcon } from "./assets";
import "../styles.css";
import "./styles.css";

const configJSON = require("../config.js");

export class EmailTemplatesCreate extends EmailTemplatesCreateController {
  render() {
    const { selectedTab, content, defaultEmailSettings } = this.state;
    const normalizedContent = normalizeContentWithDefaultEmailSettings(
      content || "",
      defaultEmailSettings
    );
    return (
      <div className="email-template-create">
        {this.state.editorMaximized && (
          <Prompt
            message={(location, action) => {
              return true;
            }}
          />
        )}
        <div className="email-template-create-left">
          <Scrollbars>
            <div className="header">
              <div
                className="icon-back-wrapper"
                onClick={this.handleBackButtonClick}
              >
                <i
                  className="icon-arrow-right"
                  onClick={this.handleBackButtonClick}
                />
                <span>{configJSON.textBack}</span>
              </div>

              <span className="header-title">
                {this.state.titleInit || configJSON.textCreateEmail}
              </span>
              <span className="hint">{configJSON.textQuickEmailPreview}</span>
              <div className="tabs-wrapper">
                <Tabs onTabChange={this.handleTabChange} value={selectedTab} />
              </div>
            </div>
            <div className="container">
              <div
                className={`preview-wrapper ${
                  selectedTab === 0
                    ? "desktop"
                    : selectedTab === 1
                    ? "tablet"
                    : "mobile"
                }`}
              >
                <Preview value={normalizedContent} />
                <PreviewFooter />
              </div>
            </div>
          </Scrollbars>
        </div>
        <Scrollbars style={{ width: "39.65%" }}>
          <div className="email-template-create-right">
            <div className="email-template-create-right-container">
              <span className="header-title">{configJSON.textEdit}</span>
              <span className="header-title-2">{configJSON.textTitle} *</span>
              <TextField
                id="standard-size-small"
                size="small"
                variant="outlined"
                fullWidth
                className="title-input"
                value={this.state.title || ""}
                onChange={this.handleTitleChange}
                error={this.state.titleError}
                helperText={
                  this.state.titleError && configJSON.textThisFieldIsRequired
                }
              />
              <span className="header-title-2">{configJSON.textContent} *</span>
              <Editor
                value={this.state.content}
                onChange={this.handleEditorChange}
                onMaximize={this.handleMaximize}
                height="clamp(200px, calc(100vh - 550px), calc(100vh - 550px))"
                error={!!this.state.contentError}
              />
              {this.state.contentError && (
                <FormHelperText error={true}>
                  {configJSON.textThisFieldIsRequired}
                </FormHelperText>
              )}
            </div>
          </div>
        </Scrollbars>
      </div>
    );
  }
}

interface TabProps {
  onTabChange?:
    | ((event: React.ChangeEvent<{}>, value: any) => void)
    | undefined;

  value: any;
}

const Tabs = ({ onTabChange, value }: TabProps) => {
  return (
    <MuiTabs
      classes={{
        root: "tabs-root",
        indicator: "tabs-indicator",
        flexContainer: "tabs-flex-container",
      }}
      value={value}
      onChange={onTabChange}
    >
      {[
        [configJSON.textDesktop, DesktopIcon],
        [configJSON.textTablet, TabletIcon],
        [configJSON.textMobile, MobileIcon],
      ].map(([label, Icon], index) => (
        <MuiTab
          // @ts-ignore
          key={label}
          classes={{
            root: "tabs-root",
            labelIcon: "tab-label-icon",
            selected: "tab-label-selected",
            wrapper: "tab-icon-wrapper",
          }}
          label={label}
          icon={
            <Icon
              className="tab-icon"
              color={value === index ? "#6200EA" : "#9B9FB1"}
            />
          }
        />
      ))}
    </MuiTabs>
  );
};

const PreviewFooter = () => {
  return (
    <div className="preview-footer">
      <div className="images">
        <img src={facebookIcon} alt="facebook" />
        <img src={twitterIcon} alt="twitter" />
        <img src={linkedInIcon} alt="linkedin" />
      </div>
      <div className="location">{configJSON.textAddress}</div>
      <div className="buttons">
        <button>{configJSON.textUnsubscribe}</button>
        <button>{configJSON.textEmailPreferences}</button>
      </div>
    </div>
  );
};

const normalizeContentWithDefaultEmailSettings = (
  content: string,
  defaultEmailSettings: any
) => {
  let normalizedContent = content;
  if (defaultEmailSettings.logo) {
    normalizedContent = normalizedContent.replace(
      /%{brand_logo}/gi,
      `<img style='width: 100px;height:100px;' src='${defaultEmailSettings?.logo}' />`
    );
  }

  if (defaultEmailSettings.brand_name) {
    normalizedContent = normalizedContent.replace(
      /%{brand_name}/gi,
      defaultEmailSettings?.brand_name
    );
  }
  return normalizedContent;
};

export default withLoader(
  withSnackBar(withHeaderBar(withDialog(EmailTemplatesCreate)))
);
