import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import RubikRegular from "./fonts/Rubik-Regular.ttf";
const rubik = {
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 300,
  src: `
    local('Rubik'),
    local('Rubik-Regular'),
    url(${RubikRegular}) format('ttf')`,
};
export const COLOR_PALETTE = {
  palette: {
    type: "light",
    white: "#fff",
    black: "#000",
    primary: {
      main: "#6200EA",
      contrastText: "#fff",
    },
    secondary: {
      main: "#00D659",
      contrastText: "#3C3E49",
    },
    text: {
      primary: "#676B7E",
      secondary: "#AFB2C0",
      blue: "#3a3f63",
      blue2: "rgba(72, 100, 132, 1)",
    },
    background: {
      default: "#fff",
      paper: "#fff",
    },
    error: {
      main: "#FEA075", // orange
      // #ffa175
    },
    warning: {
      main: "#FCC65A", //'yellow'
    },
    info: {
      main: "#345E7D", // blue
    },
    success: {
      main: "#54A4A6", // cyan
    },
    alert: {
      main: "#E10707",
    },
    // error: {
    //   main: '#ef3e22'
    // }
    // #3a3f63
    // #272b2b
  },
};
const theme = createTheme({
  ...COLOR_PALETTE,
  typography: {
    fontFamily: "Rubik",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [rubik],
      },
    },
    MuiButton: {
      textPrimary: {
        textTransform: "capitalize",
        textDecoration: "underline",
        fontSize: "12px",
        color: "#3C3E49",
        fontWeight: "400",
        cursor: "pointer",
      },
      containedPrimary: {
        textTransform: "capitalize",
        padding: "8px 32px",
        borderRadius: "3px",
        backgroundColor: "#00D659",
        color: "#3C3E49",
        fontSize: "16px",
        fontWeight: 500,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: "128px",
        "&:hover": {
          backgroundColor: "#00D659",
          color: "#3C3E49",
        },
      },
      startIcon: {
        marginRight: "2px",
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "24px",
        color: "#3C3E49",
      },
    },
    MuiOutlinedInput: {
      root: {
        "&$multiline": {
          paddingRight: "4px",
          paddingBottom: "4px",
        },
        "&$disabled $notchedOutline": {
          background: "rgba(20,20,20,0.05)",
        },
        "&$error $notchedOutline": {
          borderColor: "D50000",
        },
      },
    },
    MuiInputLabel: {
      root: {
        "&$focused": {
          color: "black",
        },
        "&$error": {
          color: "black",
        },
        "&$disabled": {
          color: "#83889E",
        },
      },
      asterisk: {
        "&$focused": {
          color: "black",
        },
        "&$error": {
          color: "black",
        },
        "&$disabled": {
          color: "#83889E",
        },
      },
    },
    MuiFormHelperText: {
      root: {
        "&.Mui-error": {
          color: "#D50000",
        },
      },
      contained: {
        marginLeft: 0,
      },
    },
  },
});

export default responsiveFontSizes(theme);
