// @ts-nocheck
import * as React from 'react';
import { useEffect, useState } from 'react';
import Nav from '../Nav';
import FadeInDropdown from '../Dropdown/FadeInDropdown/FadeInDropdown';
import { RoundIconBadge } from '../Badge/RoundIconBadge/RoundIconBadge';
import { Scrollbars } from 'react-custom-scrollbars';
import CircularProgressBar from '../Charts/CircularProgressBar';
import './assets/css/style.css';
const Logo: any = require('./assets/images/temp/logo.png');

interface LayoutProps {
    children: any
}

export const Layout: React.FunctionComponent<LayoutProps> = (props) => {
    const [hideNav, setHideNav] = useState(false);
    const [adminUser, setAdminUser] = useState(localStorage.getItem("adminuser") ? JSON.parse(localStorage.getItem("adminuser")) : {})
    const [progress, setProgress] = useState(localStorage.getItem("progress") ? JSON.parse(localStorage.getItem("progress")) : 0)

    useEffect(() => {
        function checkLocalStorageData() {
            const admin = JSON.parse(localStorage.getItem('adminuser'))
            const progressData = JSON.parse(localStorage.getItem('progress'))

            if (admin) {
                setAdminUser(admin)
            }
            if (progressData) {
                setProgress(progressData)
            }
        }
        window.addEventListener('storage', checkLocalStorageData)

        return () => {
            window.removeEventListener('storage', checkLocalStorageData)
        }
    }, [])

    const { children } = props;
    let menuList = [
        {
            label: 'Theme',
            iconEl: <RoundIconBadge iconName="theme" />
        },
        {
            label: 'Header',
            iconEl: <RoundIconBadge iconName="header" />
        },
        {
            label: 'Footer',
            iconEl: <RoundIconBadge iconName="footer" />
        },
        {
            label: 'Banners',
            iconEl: <RoundIconBadge iconName="orders" />
        },
        {
            label: 'Products',
            iconEl: <RoundIconBadge iconName="products" />
        },
        {
            label: 'Business settings',
            iconEl: <RoundIconBadge iconName="settings" />
        }
    ]
    let profileList = [
        {
            label: 'Account Overview',
            iconName: "user",
            internalUrl: "/account/overview"
        },
        {
            label: 'Logout',
            showRightArrow: false,
            iconName: "logout"
        }
    ]
    return (
        <div className={`admin-layout ${hideNav ? 'admin-layout--hide-nav' : ''}`}>
            <div className='admin-layout__sidebar'>
                <div className='admin-layout__brand-logo'>
                    <img
                        className='img-fluid'
                        src={Logo}
                        alt="logo"
                    />
                </div>
                <Scrollbars>
                    <div className='admin-layout__link-container'>
                        <Nav />
                    </div>
                </Scrollbars>
            </div>
            <div className='admin-layout__main'>
                <div className='admin-layout__nav d-flex align-items-center justify-content-between'>
                    <div className='f-14 d-flex align-items-center fw-normal text-capitalize'>
                        <a
                            href="#"
                            className='admin-layout__hamburger'
                            onClick={
                                (e) => {
                                    e.preventDefault();
                                    setHideNav(!hideNav)
                                }
                            }
                        >
                            <i className='icon-menu f-18'></i>
                        </a>
                        &nbsp;
                        &nbsp;
                        {adminUser?.data?.attributes?.store_name || "Store Name"}
                    </div>
                    <div className='d-flex align-items-center'>
                        {/* <FadeInDropdown
                            className='me-4'
                            menuLists={menuList}
                            label="Setup guide"
                            badgeEl={<CircularProgressBar percentage={progress} />}
                            menuLabel={"Setup guide"}
                        /> */}
                        <FadeInDropdown
                            menuLists={profileList}
                            label={`Hi, ${adminUser?.data?.attributes?.name || "Admin User"}`}
                            menuHeader={
                                <div className=''>
                                    <h6 className='f-14 mb-0 text-blue-grey-10'>{`${adminUser?.data?.attributes?.name || "Admin User"}`}</h6>
                                    <p className='f-10 mb-0 text-blue-grey-8'>{`${adminUser?.data?.attributes?.email || "Admin@User.com"}`}</p>
                                </div>
                            }
                            badgeName='sj'
                            menuLabel={"Setup guide"}
                        />
                    </div>
                </div>
                <div className='admin-layout__content'>
                    {children}
                </div>
            </div>
        </div>
    );
}