import React from "react";
import parser from "html-react-parser";
import { CKEditor as CKEditorV4, CKEditorEventHandler } from "ckeditor4-react";
import "./styles.css";

interface Props {
  value?: string;
  onChange: (newValue: string, plainText?: string) => void;
  onMaximize?: CKEditorEventHandler<"maximize">;
  height?: string | number;
  error?: boolean;
}

export const Editor = (props: Props) => {
  const { value, onChange, onMaximize, height, error } = props;

  const handleChange: CKEditorEventHandler<"change"> = (e) => {
    onChange(
      e.editor.getData().replace(/[\r\n\t]/gm, ""),
      e.editor.document.getBody().getText()
    );
  };

  return (
    <div className="ck-editor-wrapper">
      {/* start value with undefined, then change it to empty string if you dont want to show any data (for example on create pages) */}
      {value != null ? (
        <CKEditorV4
          config={{
            allowedContent: true,
            // autoGrow_maxHeight: 49999,
            height: height || "auto",
            fullPage: true,
            // resize_maxHeight: 9999,
            resize_enabled: false,
            removeButtons: "PasteText,PasteFromWord,Paste",
            // bodyClass: "ck-editor-v4-body",
            // contentsCss: "/src/packages/blocks/studio-store-ecommerce-components/src/Editor-v4/styles.css"
          }}
          style={{
            borderColor: error ? "red" : undefined,
          }}
          initData={value}
          onChange={handleChange}
          readOnly={false}
          type="classic"
          onMaximize={onMaximize}
          onPaste={(...args) => console.log(args)}
        />
      ) : null}
    </div>
  );
};

interface PreviewProps {
  value?: string;
  className?: string;
  [key: string]: any;
}

export const Preview = ({ value, className = "", ...props }: PreviewProps) => {
  return (
    <div className={"wysiwyg-editor-preview " + className} {...props}>
      {parser(value || "")}
    </div>
  );
};

export default Editor;
