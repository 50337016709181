//@ts-nocheck
import React, { Component } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import jwt from "jsonwebtoken";


export type Props = RouteProps & {

};

interface S {
    token: string;
    loading: boolean;
}

export default class ProtectedRoute extends Component<Props, S>{
    render() {
        let token = window.localStorage.getItem("admintoken");
        const expired = token
            ? // @ts-ignore
            new Date().getTime() > (jwt.decode(token)?.exp || 0) * 1000
            : true;

        if (expired) {
            window.localStorage.removeItem("admintoken");
        }

        return expired ? <Redirect to="/login" /> : <Route {...this.props} />;
    }
};