import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const GlobalLoading = () => (
  <Backdrop style={{ zIndex: 9999 }} open>
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
        <CircularProgress size="5rem" color="primary" />
    </div>
  </Backdrop>
)

export default GlobalLoading;
